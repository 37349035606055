import React,{ useState, useEffect, useContext, createContext } from "react"

const AppContext = createContext({
    logout : ()=>{},
    user : {},
    token : "",
    exp : null,
    exp_mili : null
})

const AppProvider = ({children}) =>{
    const [user , setUser] = useState( localStorage.hasOwnProperty('currentUser') ? JSON.parse(localStorage.currentUser) : {} )
    const [token , setToken] = useState(localStorage.hasOwnProperty('token') ? localStorage.token : null)
    const [exp , setExp] = useState(localStorage.hasOwnProperty('exp') ? localStorage.exp : null)
    const [exp_mili, setExpMili] = useState(localStorage.hasOwnProperty('exp_mili') ? localStorage.exp_mili : null)

    useEffect(()=>{
        if(exp_mili < new Date().getTime()){
            if(window.location.pathname !== "/auth/login"){
                localStorage.clear()
                window.location.href = '/auth/login';
            }
        }
    },[])

    const logout = () => {
        localStorage.clear()
        window.location.href = '/auth/login';
    }
    

    return(
        <AppContext.Provider value={{
            logout,
            user : user,
            token : token,
            exp : exp,
            exp_mili : exp_mili,
        }}>
            { 
                children
            }
        </AppContext.Provider>
    )
}

export const useAppContext = () =>{
    const context = useContext(AppContext)
    if (context === undefined) {
        throw new Error('useAppContext must be used within a ContextProvider')
    }
    return context
}

export default AppProvider