import { 
  DashboardOutlined,
  UsergroupAddOutlined,
  DropboxOutlined,
  AlertOutlined,
  TrademarkCircleOutlined,
  ToolOutlined,
  PaperClipOutlined,
  BugOutlined,
  OrderedListOutlined,
  ScheduleOutlined,
  ReconciliationOutlined,
  SnippetsOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
  {
    key: 'usuarios',
    path: `${APP_PREFIX_PATH}/usuarios`,
    title: 'Usuarios',
    icon: UsergroupAddOutlined,
    breadcrumb: true,
    submenu: []
  },
  {
    key: 'productos',
    path: `${APP_PREFIX_PATH}/productos`,
    title: 'Productos',
    icon: DropboxOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'clientes',
    path: `${APP_PREFIX_PATH}/clientes`,
    title: 'Clientes',
    icon: UsergroupAddOutlined,
    breadcrumb: true,
    submenu: []
  },
  {
    key: 'oportunidades',
    path: `${APP_PREFIX_PATH}/oportunidades`,
    title: 'Oportunidades',
    icon: SnippetsOutlined,
    breadcrumb: true,
    submenu: []
  },
  {
    key: 'cotizaciones',
    path: `${APP_PREFIX_PATH}/cotizaciones`,
    title: 'Cotizaciones',
    icon: PaperClipOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'taller',
    path: `${APP_PREFIX_PATH}/servicios`,
    title: 'Servicios',
    icon: ToolOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'rentas',
    path: `${APP_PREFIX_PATH}/rentas`,
    title: 'Rentas',
    icon: TrademarkCircleOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'logistica',
    path: `${APP_PREFIX_PATH}/logistica/lista`,
    title: 'Logistica',
    icon: AlertOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'tareas',
    path: `${APP_PREFIX_PATH}/tareas/lista`,
    title: 'Tareas',
    icon: OrderedListOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'soporte_interno',
    path: `${APP_PREFIX_PATH}/soporte_interno/lista`,
    title: 'Soporte Interno',
    icon: ScheduleOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'seguimientos',
    path: `${APP_PREFIX_PATH}/seguimientos`,
    title: 'Seguimientos',
    icon: ReconciliationOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'agricultura',
    path: ``,
    title: 'Agricultura',
    icon: BugOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'demostraciones',
        path: `${APP_PREFIX_PATH}/agricultura/demostraciones`,
        title: 'Demostraciones',
        icon: '',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'instalaciones',
        path: `${APP_PREFIX_PATH}/agricultura/instalaciones`,
        title: 'Instalaciones',
        icon: '',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'stock',
        path: `${APP_PREFIX_PATH}/agricultura/stock/lista`,
        title: 'Stock',
        icon: '',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'venta',
        path: `${APP_PREFIX_PATH}/agricultura/ventas`,
        title: 'Venta',
        icon: '',
        breadcrumb: false,
        submenu: []
      },
    ]
  },
  {
    key: 'topografia',
    path: ``,
    title: 'Topografia',
    icon: BugOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'proyectos',
        path: `${APP_PREFIX_PATH}/topografia/proyectos`,
        title: 'Proyectos',
        icon: '',
        breadcrumb: false,
        submenu: []
      },
    ]
  },
]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
