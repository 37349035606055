import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import 'antd-button-color/dist/css/style.css';
export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/clientes`} component={lazy(() => import(`./clientes`))} />
        <Route path={`${APP_PREFIX_PATH}/cliente/:id`} component={lazy(() => import(`./clientes/cliente`))} />
        <Route path={`${APP_PREFIX_PATH}/productos`} component={lazy(() => import(`./productos/productos`))} />
        <Route path={`${APP_PREFIX_PATH}/producto/:id`} component={lazy(() => import(`./productos/producto`))} />
        <Route path={`${APP_PREFIX_PATH}/usuarios`} component={lazy(() => import(`./usuarios`))} />
        <Route path={`${APP_PREFIX_PATH}/usuario/:id`} component={lazy(() => import(`./usuarios/Usuario`))} />
        <Route path={`${APP_PREFIX_PATH}/cotizaciones`} component={lazy(() => import(`./cotizaciones`))} />
        <Route path={`${APP_PREFIX_PATH}/cotizacion/:id_quotation`} component={lazy(() => import(`./cotizaciones/cotizacion`))} />
        <Route path={`${APP_PREFIX_PATH}/servicios`} component={lazy(() => import(`./taller/Lista`))} />
        <Route path={`${APP_PREFIX_PATH}/servicio/:id`} component={lazy(() => import(`./taller/Servicio`))} />
        <Route path={`${APP_PREFIX_PATH}/soporte/:id`} exact component={lazy(() => import(`./taller/Soporte`))} />
        <Route path={`${APP_PREFIX_PATH}/rentas`} component={lazy(() => import(`./rentas/Lista`))} />
        <Route path={`${APP_PREFIX_PATH}/renta/:id`} component={lazy(() => import(`./rentas/Renta`))} />
        <Route path={`${APP_PREFIX_PATH}/logistica/lista`} component={lazy(() => import(`./logistica/Lista`))} />
        <Route path={`${APP_PREFIX_PATH}/logistica/:id`} component={lazy(() => import(`./logistica/Logistica`))} />

        <Route exact path={`${APP_PREFIX_PATH}/agricultura/demostraciones`} component={lazy(() => import(`./agricultura/Demostraciones`))} />
        <Route exact path={`${APP_PREFIX_PATH}/agricultura/demostracion/:id`} component={lazy(() => import(`./agricultura/Demostracion`))} />
        <Route exact path={`${APP_PREFIX_PATH}/agricultura/ver/demostracion/:id`} component={lazy(() => import(`./agricultura/ver/Demostracion`))} />

        <Route  path={`${APP_PREFIX_PATH}/agricultura/instalaciones`} component={lazy(() => import(`./agricultura/Instalaciones`))} />
        <Route  path={`${APP_PREFIX_PATH}/agricultura/instalacion/:id`} component={lazy(() => import(`./agricultura/Instalacion`))} />
        <Route  path={`${APP_PREFIX_PATH}/agricultura/ver/instalacion/:id`} component={lazy(() => import(`./agricultura/ver/Instalacion`))} />
        
        <Route path={`${APP_PREFIX_PATH}/agricultura/stock/lista`} component={lazy(() => import(`./agricultura/Stocks`))} />
        <Route path={`${APP_PREFIX_PATH}/agricultura/stock/:id`} component={lazy(() => import(`./agricultura/Stock`))} />
        <Route path={`${APP_PREFIX_PATH}/agricultura/ver/stock/:id`} component={lazy(() => import(`./agricultura/ver/Stock`))} />

        <Route path={`${APP_PREFIX_PATH}/agricultura/ventas`} component={lazy(() => import(`./agricultura/Ventas`))} />
        <Route path={`${APP_PREFIX_PATH}/agricultura/venta/:id`} component={lazy(() => import(`./agricultura/Venta`))} />
        <Route path={`${APP_PREFIX_PATH}/agricultura/ventas/ver/:id`} component={lazy(() => import(`./agricultura/ver/Venta`))} />
        
        <Route path={`${APP_PREFIX_PATH}/topografia/proyectos`} component={lazy(() => import(`./topografia/Proyectos`))} />
        <Route path={`${APP_PREFIX_PATH}/topografia/agregar/:id`} component={lazy(() => import(`./topografia/Agregar`))} />
        <Route path={`${APP_PREFIX_PATH}/topografia/seguimiento/:id`} component={lazy(() => import(`./topografia/Seguimiento`))} />

        <Route path={`${APP_PREFIX_PATH}/tareas/lista`} component={lazy(() => import(`./tareas/Lista`))} />
        <Route path={`${APP_PREFIX_PATH}/seguimiento/cliente/:id`} component={lazy(() => import(`./clientes/Seguimiento`))} />

        <Route path={`${APP_PREFIX_PATH}/soporte_interno/lista`} component={lazy(() => import(`./soporte_interno/Lista`))} />
        <Route path={`${APP_PREFIX_PATH}/soporte_interno/:id`} component={lazy(() => import(`./soporte_interno/Alta`))} />
        <Route path={`${APP_PREFIX_PATH}/seguimientos`} component={lazy(() => import(`./Seguimiento`))} />
        <Route path={`${APP_PREFIX_PATH}/oportunidades`} component={lazy(() => import(`./oportunidades/Oportunidades`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/clientes`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);